// @flow strict
import React from 'react';
import classNames from 'classnames/bind';
import { Link } from 'gatsby';
import { PAGINATION } from '../../constants';
import styles from './Pagination.module.scss';

type Props = {
  numPages: number,
  currentPage: number,
  paginationSlug: string
};

const SHOW_NUMBERS = PAGINATION.SHOW_NUMBERS;

const PageNumbers = (
  {
    numPages,
    currentPage,
    paginationSlug
  }: props) => {

  let pagesToShow = [];
  if (numPages <= 5) {
    for (let i = 0; i < numPages; i += 1) {
      pagesToShow.push(i);
    }
  } else {
    // console.log(currentPage);
    // currentPage is 0-indexed
    ++currentPage;
    pagesToShow.push(0);

    // for cases like: 1 2 3 4 ... 10
    if (currentPage <= SHOW_NUMBERS - 2) {
      for (let i = 1; i <= SHOW_NUMBERS - 2; ++i) {
        pagesToShow.push(i);
      }
      pagesToShow.push(-1);
    // for cases like: 1 ... 7 8 9 10
    } else if (currentPage >= numPages - (SHOW_NUMBERS - 1)) {
      pagesToShow.push(-1);
      for (let i = numPages - (SHOW_NUMBERS - 1); i < numPages - 1; ++i) {
        pagesToShow.push(i);
      }
    // for cases like: 1 ... 3 4 5 ... 10; 1 ... 4 5 6 ... 10
    } else {
      pagesToShow.push(-1);
      pagesToShow.push(currentPage - 1);
      pagesToShow.push(currentPage);
      pagesToShow.push(currentPage + 1);
      pagesToShow.push(-1);
    }

    pagesToShow.push(numPages - 1);
  }
  return (
    <div className={styles['pageNumbersWrapper']}>
      {pagesToShow.map((x, i) =>
        <div className={styles['pageNumbers']}>
          {
            x == -1 ? (
              <div className="">...</div>
            ) : (
              <Link
                to={x == 0 ?
                  `${paginationSlug}`:
                  `${paginationSlug}/page/${x}`
                }
                activeStyle={{ color: "rgb(0,100,0)" }}
              >
                {x + 1}
              </Link>
            )
          }
        </div>
      )}
    </div>
  );
};

export default PageNumbers;
